import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NoData } from "../../assets";
import BasicPopover from "../../shared/components/BasicPopOver";
import Loader from "../../shared/components/Loader";
import Confirmation from "../../shared/components/Modals/Confirmation";
import Submissions from "../../shared/components/Modals/Submissions";
import ViewDetail from "../../shared/components/Modals/ViewDetail";
import Pagination from "../../shared/components/Pagination";
import { toastMessage } from "../../shared/components/Toast";
import {
  completedList,
  deleteContestService,
  unHideService,
} from "../../shared/services/contestServices";
import ViewArtDetail from "./../../shared/components/Modals/ViewDetailArt";

export default function BasicTable() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [subOpen, setSubOpen] = useState(false);
  const [selectedArt, setSelectedArt] = useState({});
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [active, setActive] = useState(1);
  const [loader, setLoader] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedContest, setSelectedContest] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [check, setCheck] = useState("");

  const handleOpenConfirmation = (check, item) => {
    setCheck(check);
    setSelectedContest(item);
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleOpenDetail = (item) => {
    item?.on_going_contest_participants_new?.map((user) => {
      if (user.is_winner == 1) {
        setSelectedUser(user?.user);
      }
    });
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleOpenView = (item) => {
    setShow(true);
    setSelectedArt(item?.art);
  };

  const handleCloseView = () => {
    setShow(false);
  };

  const handleSubOpen = (item) => {
    setSubOpen(true);
    setSelectedArt(item?.art);
    setSelectedParticipants(item?.on_going_contest_participants_new);
  };

  const handleSubClose = () => {
    setSubOpen(false);
  };

  const getOngoingContest = () => {
    if (active == 1) {
      setLoader(true);
    }
    completedList(active)
      .then(({ data } = data) => {
        setLoader(false);
        setData(data?.data?.contests?.data);
        setLastPage(data?.data?.contests?.last_page);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  const handleConfirmation = (resp, selectedUser) => {
    deleteContestService(selectedUser?.id)
      .then(({ data: { message } }) => {
        setData(data.filter((item) => item.id !== selectedUser?.id));
        toastMessage(message);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleUnhide = (item, status) => {
    let obj = {
      id: item?.id,
      isHide: status ? "1" : "0",
    };

    unHideService(obj)
      .then(({ data: { message } }) => {
        toastMessage(message);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getOngoingContest();
  }, [active]);

  return (
    <Box>
      <Typography
        sx={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 32,
          lineHeight: "48px",
          letterSpacing: "-0.5px",
          color: "#11142D",
          marginBottom: "2%",
        }}
      >
        Completed Contest
      </Typography>

      <br />
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        {loader ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
          >
            <Loader />
          </Box>
        ) : data.length ? (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Art </TableCell>
                <TableCell>Deadline </TableCell>
                <TableCell>Instructions</TableCell>
                <TableCell>Art detail</TableCell>
                <TableCell>Submissions</TableCell>
                <TableCell>Visibility</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, inx) => (
                <TableRow
                  key={item?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{inx + 1}</TableCell>
                  <TableCell>
                    <Box className="tableBox">
                      {item?.art?.title ? item?.art?.title : "Not Provided"}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {moment(item?.end_date).format(" Do MMMM YYYY")}
                  </TableCell>
                  <TableCell>
                    <BasicPopover
                      reason={
                        item?.instructions ? item?.instructions : "Not Provided"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#101828",
                        color: "white",
                        "&:hover": {
                          background: "#101828",
                        },
                      }}
                      onClick={() => handleOpenView(item)}
                    >
                      <VisibilityIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className="btnView"
                      sx={{
                        background: "#50934B",
                        color: "white",
                        "&:hover": {
                          background: "#50934B",
                        },
                      }}
                      onClick={() => handleSubOpen(item)}
                    >
                      <FolderOpenIcon />
                    </Button>
                  </TableCell>

                  <TableCell>
                    {item?.is_active ? (
                      <Button
                        sx={{
                          background: " rgba(255, 183, 190, 0.9);",
                        }}
                        onClick={() => handleUnhide(item, true)}
                      >
                        <VisibilityOffIcon sx={{ color: "red" }} />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className="btnView"
                        sx={{
                          background: " rgba(207 , 255, 204, 0.9);",
                          marginLeft: "5%",
                          boxShadow: "none",
                          "&:hover": {
                            background: "rgba(207 , 255, 204, 0.9)",
                          },
                        }}
                        onClick={() => handleUnhide(item, false)}
                      >
                        <VisibilityIcon sx={{ color: "green" }} />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{
                        background: " rgba(255, 183, 190, 0.9);",
                        marginLeft: "5%",
                      }}
                      onClick={(e) =>
                        handleOpenConfirmation("Delete this Contest", item)
                      }
                    >
                      <DeleteIcon sx={{ color: "red" }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            <Box className="noDataBox">
              <img src={NoData} />
            </Box>
            <Typography className="noDataText">No results found</Typography>
          </>
        )}
      </TableContainer>
      {lastPage > 1 && !loader ? (
        <Pagination lastPage={lastPage} active={active} setActive={setActive} />
      ) : (
        ""
      )}
      <ViewArtDetail
        open={show}
        handleClickOpen={handleOpenView}
        handleClose={handleCloseView}
        setSelectedArt={setSelectedArt}
        selectedArt={selectedArt}
        check={"Art"}
      />
      <ViewDetail
        open={openDetail}
        handleClickOpen={handleOpenDetail}
        handleClose={handleCloseDetail}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <Submissions
        open={subOpen}
        handleClickOpen={handleSubOpen}
        handleClose={handleSubClose}
        setSelectedArt={setSelectedArt}
        selectedArt={selectedArt}
        selectedParticipants={selectedParticipants}
        setSelectedParticipants={setSelectedParticipants}
        check={"Completed"}
      />

      <Confirmation
        open={showConfirmation}
        handleClose={handleCloseConfirmation}
        check={check}
        selectedUser={selectedContest}
        handleConfirmation={handleConfirmation}
      />
    </Box>
  );
}
