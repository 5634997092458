import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useLocation, useNavigate } from "react-router-dom";
import Confirmation from "./Modals/Confirmation";
import { Logo } from "./../../assets/index";

//icons
import GridViewIcon from "@mui/icons-material/GridView";
import GroupIcon from "@mui/icons-material/Group";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ArticleIcon from "@mui/icons-material/Article";
import FlagIcon from "@mui/icons-material/Flag";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CommentIcon from "@mui/icons-material/Comment";
import QuizIcon from "@mui/icons-material/Quiz";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BugReportIcon from "@mui/icons-material/BugReport";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import InfoIcon from "@mui/icons-material/Info";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddIcon from "@mui/icons-material/Add";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CampaignIcon from "@mui/icons-material/Campaign";
import PaidIcon from "@mui/icons-material/Paid";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DownloadingIcon from "@mui/icons-material/Downloading";
import BrushIcon from "@mui/icons-material/Brush";
import ViewListIcon from "@mui/icons-material/ViewList";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

const drawerWidth = 290;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 25px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 25px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

let sideBarList = [
  {
    name: "Dashboard",
    path: "/",
    icon: <GridViewIcon />,
  },
  {
    name: "Users",
    path: "/User",
    icon: <ManageAccountsIcon />,
    expand: false,
    children: [
      // {
      //   name: "All Users",
      //   path: "/users",
      //   icon: <GroupIcon />,
      // },
      {
        name: "Writers",
        path: "/writers",
        icon: <AssignmentIndIcon />,
      },
      {
        name: "Artists",
        path: "/artists",
        icon: <SwitchAccountIcon />,
      },
      // {
      //   name: "Blocked Users",
      //   path: "/blocked-users",
      //   icon: <NoAccountsIcon />,
      // },
      {
        name: "Feature Artists",
        path: "/featureArtists",
        icon: <LocalFireDepartmentIcon />,
      },
      {
        name: "Feature Writer",
        path: "/featureWriter",
        icon: <EditNoteIcon />,
      },
    ],
  },
  {
    name: "Art Collection",
    path: "/art",
    icon: <ColorLensIcon />,
  },
  {
    name: "Story Collection",
    path: "/approve",
    icon: <ArticleIcon />,
  },
  {
    name: "External Contest",
    path: "/external-contest",
    icon: <MilitaryTechIcon />,
  },
  {
    name: "Post Arrangement",
    path: "/feature-art",
    icon: <ViewListIcon />,
  },
  {
    name: "Contests",
    path: "/Contest",
    icon: <EmojiEventsIcon />,
    expand: false,
    children: [
      {
        name: "Ongoing Contest",
        path: "/ongoingContest",
        icon: <SportsScoreIcon />,
      },
      {
        name: "Completed Contest",
        path: "/completedContest",
        icon: <AssignmentTurnedInIcon />,
      },
      {
        name: "Contest Deadline",
        path: "/contestDeadline",
        icon: <AccessTimeFilledIcon />,
      },
    ],
  },
  {
    name: "Contact Us",
    path: "/contactus",
    icon: <SupportAgentIcon />,
  },

  {
    name: "Donation List",
    path: "/buy-me-coffee",
    icon: <VolunteerActivismIcon />,
  },
  {
    name: "News Letter",
    path: "/newsLetter",
    icon: <NewspaperIcon />,
  },
  {
    name: "Store",
    path: "/store",
    icon: <PaidIcon />,
  },
  {
    name: "Announcements",
    path: "/announcements",
    icon: <CampaignIcon />,
  },
  {
    name: "Report",
    path: "/report",
    icon: <FlagIcon />,
    expand: false,
    children: [
      {
        name: "Reported Comment",
        path: "/reportedComment",
        icon: <CommentIcon />,
      },
      {
        name: "Reported Story",
        path: "/reportedStory",
        icon: <ReportProblemIcon />,
      },
      {
        name: "Reported Art",
        path: "/reportedArt",
        icon: <BugReportIcon />,
      },
    ],
  },

  {
    name: "Add Info & Policies",
    path: "/info",
    icon: <InfoIcon />,
    expand: false,
    children: [
      {
        name: "FAQs",
        path: "/faq",
        icon: <QuizIcon />,
      },
      {
        name: "Writing Tips",
        path: "/writingTips",
        icon: <TipsAndUpdatesIcon />,
      },
      {
        name: "Terms and Condition",
        path: "/terms",
        icon: <AssignmentIcon />,
      },
      {
        name: "Privacy Policy",
        path: "/privacy",
        icon: <PrivacyTipIcon />,
      },
      {
        name: "Platform Rules",
        path: "/home-guide",
        icon: <LibraryBooksIcon />,
      },
      {
        name: "Testimonial",
        path: "/testimonial",
        icon: <RateReviewIcon />,
      },
    ],
  },
];

export default function MiniDrawer(props) {
  const [sideBarListItems, setsideBarListItems] = useState(sideBarList);
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [showLogout, setShowLogout] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenLogout = () => {
    setShowLogout(true);
  };

  const handleCloseLogout = () => {
    setShowLogout(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const DashItem = (item, inx, event) => {
    event.stopPropagation();
    if (item?.children) {
      let temp = [...sideBarListItems];
      temp[inx]["expand"] = !item.expand;
      setsideBarListItems(temp);
    } else {
      navigate(item.path);

      if (width <= 767) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    if (width < 767) {
      setOpen(false);
    } else if (width > 767) {
      setOpen(true);
    }
  }, [width]);

  useEffect(() => {
    const updatedListItems = sideBarListItems.map((item) => {
      if (location.pathname.includes(item.path)) {
        return {
          ...item,
          expand: true,
        };
      } else if (item.children) {
        const updatedChildren = item.children.map((childItem) => {
          if (location.pathname.includes(childItem.path)) {
            return {
              ...childItem,
              expand: true,
            };
          }
          return childItem;
        });
        return {
          ...item,
          expand: updatedChildren.some((childItem) =>
            location.pathname.includes(childItem.path)
          ),
          children: updatedChildren,
        };
      }
      return item;
    });
    setsideBarListItems(updatedListItems);
  }, [location]);

  return (
    <Box sx={{ display: "flex" }} className="drawerSide">
      <CssBaseline />
      <Drawer variant="permanent" className="layoutDrawer" open={open}>
        <DrawerHeader
          sx={
            !open && width < 767
              ? { display: "flex", justifyContent: "center" }
              : open && width > 767
              ? { display: "flex", justifyContent: "center" }
              : {}
          }
          className="drawerHead"
        >
          {open ? <img src={Logo} height={50} /> : ""}
          {width > 767 ? (
            ""
          ) : (
            <IconButton onClick={handleDrawerClose}>
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Box>
          <List>
            {sideBarListItems.map((item, inx) => (
              <ListItem
                key={inx}
                disablePadding
                sx={{ display: "block" }}
                onClick={(event) => DashItem(item, inx, event)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    style={
                      item.path === props.path
                        ? {
                            margin: "0 auto",
                            background: "#101828",
                            padding: "10px",
                            color: "#FFFF",
                            borderTopLeftRadius: "12px",
                            borderBottomLeftRadius: "12px",
                            borderTopRightRadius: !open ? "12px" : "0px",
                            borderBottomRightRadius: !open ? "12px" : "0px",
                          }
                        : {
                            margin: "0 auto",
                            padding: "8px",
                            color: "rgb(128, 129, 145)",
                          }
                    }
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={
                      item.path === props.path
                        ? {
                            opacity: open ? 1 : 0,
                            color: "#FFFF",
                            background: "#101828",
                            padding: open ? "10px" : "0px",
                            paddingLeft: "0px",
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                          }
                        : {
                            opacity: open ? 1 : 0,
                            padding: open ? "10px" : "0px",
                            color: "rgb(128, 129, 145)",
                          }
                    }
                  />
                  {item.children && item.expand && width > 767 ? (
                    <KeyboardArrowUpIcon
                      style={{ color: "rgb(128, 129, 145)" }}
                    />
                  ) : item.children && !item.expand && width > 767 ? (
                    <KeyboardArrowDownIcon
                      style={{ color: "rgb(128, 129, 145)" }}
                    />
                  ) : (
                    ""
                  )}
                </ListItemButton>

                {item.children && item.expand && (
                  <List>
                    {item.children.map((childItem, childIndex) => (
                      <ListItem
                        key={childIndex}
                        disablePadding
                        sx={{ display: "block" }}
                        onClick={(event) =>
                          DashItem(childItem, childIndex, event)
                        }
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 4.5,
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                            style={
                              childItem.path === props.path
                                ? {
                                    margin: "0 auto",
                                    background: "#101828",
                                    padding: "10px",
                                    color: "#FFFF",
                                    borderTopLeftRadius: "12px",
                                    borderBottomLeftRadius: "12px",
                                    borderTopRightRadius: !open
                                      ? "12px"
                                      : "0px",
                                    borderBottomRightRadius: !open
                                      ? "12px"
                                      : "0px",
                                  }
                                : {
                                    margin: "0 auto",
                                    padding: "8px",
                                    color: "rgb(128, 129, 145)",
                                  }
                            }
                          >
                            {childItem.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={childItem.name}
                            sx={
                              childItem.path === props.path
                                ? {
                                    opacity: open ? 1 : 0,
                                    color: "#FFFF",
                                    background: "#101828",
                                    padding: open ? "10px" : "0px",
                                    paddingLeft: "0px",
                                    borderTopRightRadius: "12px",
                                    borderBottomRightRadius: "12px",
                                  }
                                : {
                                    opacity: open ? 1 : 0,
                                    padding: open ? "10px" : "0px",
                                    color: "rgb(128, 129, 145)",
                                  }
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          {/* <ListItem
            disablePadding
            sx={{ display: 'block' }}
            onClick={() => handleOpenLogout()}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
                style={{
                  margin: '0 auto',
                  padding: '8px',
                  color: '#ff6565',
                  borderTopLeftRadius: '12px',
                  borderBottomLeftRadius: '12px',
                  borderTopRightRadius: !open ? '12px' : '0px',
                  borderBottomRightRadius: !open ? '12px' : '0px',
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Logout'}
                sx={{
                  opacity: open ? 1 : 0,
                  color: '#ff6565',
                  padding: open ? '10px' : '0px',
                  borderTopRightRadius: '12px',
                  borderBottomRightRadius: '12px',
                }}
              />
            </ListItemButton>
          </ListItem> */}
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={
          width > 990
            ? { flexGrow: 1, p: 3 }
            : { flexGrow: 1, p: 3, width: `${width / 10}%` }
        }
        className="responsiveMain"
      >
        <props.component />
      </Box>
      <Confirmation
        open={showLogout}
        handleClickOpen={handleOpenLogout}
        handleClose={handleCloseLogout}
      />
    </Box>
  );
}
